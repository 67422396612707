import { ChevronRightIcon } from "@heroicons/react/20/solid"
import React from "react"
import Link from "./Link"

export default function Button({ theme, text, url, size }) {
  return (
    <div className=" group custom-sans ">
      <Link
        to={url}
        title={text}
        className={`${
          theme === "light"
            ? "bg-white text-primary hover:bg-opacity-50"
            : "bg-primary    text-white hover:bg-opacity-75 "
        }  text-${
          size || "sm"
        } px-3 py-2 lg:px-6 lg:py-2  inline-block rounded-full font-bold  transition-all`}
      >
        <div className="flex flex-row items-center justify-between  transition-all">
          <span className="block ">{text}</span>
        </div>
      </Link>
    </div>
  )
}
