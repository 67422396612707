import React from "react"
import Button from "../common/Button"
import HeaderFeaturedImage from "../common/HeaderFeaturedImage"

import PageHeader from "../common/PageHeader"
import MeetingEventMenu from "./MeetingEventMenu"

export default function MeetingsSinglePage({ data, lang }) {
  const { title, featuredImage, content } = data.wpPage

  return (
    <div>
      {featuredImage && (
        <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
      )}

      <MeetingEventMenu lang={lang} />
      <div className="wp-page max-w-4xl min-h-screen mx-auto px-4 lg:px-8 py-8 lg:py-16">
        <div className="flex flex-col gap-4 lg:gap-8">
          {!featuredImage && <PageHeader text={title} />}
          <div className="flex flex-col gap-4 lg:gap-8 ">
            <div className="flex-1 overflow-hidden space-y-8">
              <div>
                <div
                  className="wp-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>{" "}
            </div>
            <div>
              <Button
                text={lang === "sv" ? "Kontakta oss" : "Contact us"}
                size="lg"
                url={
                  lang === "sv"
                    ? "/information/kontakt"
                    : "/en/information-2/contact"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
